import React from 'react';
import DropdownBox from '../../dropdown-box';
import Checkbox from '../../checkbox/checkbox';
import { FieldArray } from 'formik';

const Terms = (props) => {

    return (
        <DropdownBox title="Electronic Signature Consent">
            <p>{props.text}</p>

            <FieldArray name="terms">
                <div>
                    {props.terms.map((term, index) => (
                        <div key={index}>
                            <Checkbox name={`terms.${index}`} label={term.title}>
                                <strong>{term.title}</strong>
                                <small>{term.subtext} <a href={term.url} target="_blank" rel="noopener noreferrer">{term.linkText || 'open in new tab'}</a></small>
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </FieldArray>
        </DropdownBox>
    )
}


export default Terms;