import React, { Component } from 'react';
import Navbar from '../navbar';
import ExperienceLogin from '../experience-login';
import RegistrationDetails from '../registration-details/registration-details';
import RegistrationConfirmation from '../registration-confirmation'

class BookingRegistration extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            details: null
        };


        this.handleDetailsValidated = this.handleDetailsValidated.bind(this);
        this.handleRegistration = this.handleRegistration.bind(this);
    }

    handleDetailsValidated(details) {
        console.log('Details validated callback, next step');
        this.setState({step: 1, details: details});
    }

    handleRegistration(details) {
        this.setState({step: 2, details: details});
    }


    render() {

        var mainContent;
        switch (this.state.step) {
            case 0:
                mainContent = <ExperienceLogin onDetailsValidated={this.handleDetailsValidated}/>
                break;
            case 1:
                mainContent = <RegistrationDetails details={this.state.details} onRegister={this.handleRegistration} type="booking"/>
                break;
            case 2:
                mainContent = <RegistrationConfirmation details={this.state.details} />
                break;
            default:
                mainContent = "Please reload the page";
                break;
        }

        return <div>
                <Navbar step={this.state.step} />
                {mainContent}
            </div>
    }
}

export default BookingRegistration;