import config from './config';
import { stripNonAscii } from './string-utils';

export default class {
    constructor() {
        this.validateDetails = this.validateDetails.bind(this);
        this.register = this.register.bind(this);
    }

    async validateDetails(bookingRef, surname) {
        // Build query
        var params = new URLSearchParams();
        params.append("bookingReference", bookingRef.toUpperCase());
        params.append("surname", surname);       

        var url = config.apiBase + "api/bookings/find?" + params.toString();
        
        var fetchResult = await fetch(url);
        if (!fetchResult.ok) {
            if (fetchResult.status === 404 || fetchResult.status === 400) {
                return false;
            }
            throw 'Unable to fetch from API';
        }

        var response = await fetchResult.json();
        if (response.length === 0) return false;

        var c = response.recipient;
        var a = response.recipient.address;

        return {
            bookingRef: response.id,
            emailAddress: c.email,
            validationSurname: surname,

            experience: response.course.name,
            eventDate: response.eventDate,
            session: response.timeSlot,
            maxAdditionalGuests: 100,

            purchaser: {
                title: c.title,
                forename:c.forename,
                surname: c.surname,
                email: c.email,
                phone: c.phone,

                address: {
                    line1: '',
                    line2: '',
                    town: '',
                    postcode: ''
                }
            },

            terms: [
                {
                    title: 'General Terms and Conditions',
                    url: 'https://barc.blob.core.windows.net/thruxton/downloads/experience-terms/driving-experience-terms-conditions.pdf',
                    subtext: 'I accept the following ',
                    linkText: 'terms and conditions'
                }
            ]
        }
    }

    async register(details) {
        const address = details.purchaser.address;

        var data = {
            bookingReference: details.bookingRef.toUpperCase(),
            bookingSurname: details.validationSurname,

            title: stripNonAscii(details.purchaser.title),
            forename: stripNonAscii(details.purchaser.forename),
            surname: stripNonAscii(details.purchaser.surname),
            email: details.purchaser.email,
            phone: stripNonAscii(details.purchaser.phone),

            address: {
                line1: stripNonAscii(address.line1),
                line2: stripNonAscii(address.line2),
                town: stripNonAscii(address.town),
                postcode: stripNonAscii(address.postcode)
            },

            guests: details.additionalGuests.map(x => ({
                name: stripNonAscii(x.name),
                email: x.email,
                phone: stripNonAscii(x.phone)
            })),

            termsAccepted: true
        };

        const response = await fetch(config.apiBase + "api/bookings/check-in", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: 'no-cache',
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw 'Error while registering';
        }

        return details;
    }
}
