import React, { Component } from 'react';
import styles from './navelement.module.scss';
import classNames from 'classnames';

class NavElement extends Component {

    render() {
        const pointerClass = classNames({
            [styles.pointer]: true,
            [styles.active]: this.props.navState === "active",
            [styles.upcoming]: this.props.navState === "upcoming",
            [styles.completed]: this.props.navState === "completed"
        });

        return <li className={pointerClass}><span>{this.props.text}</span></li>
    }
}

export default NavElement;