import React, { Component } from "react";
import logo from './logo.svg';
import croftLogo from "./croft.png"
import styles from './logo.module.scss';
import {getIsCroft} from "../../utils/croft";

class Logo extends Component {
    
    render() {
        const isCroft = getIsCroft();
        const logoSrc = isCroft ? croftLogo : logo;
        
        return <img src={logoSrc} className={styles.logo} alt={`The ${isCroft ? "Croft" : "Thruxton"} logo`} />
    }
}

export default Logo;