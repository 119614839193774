import React from 'react';
import { FieldArray } from 'formik';
import LabelledField from '../../labelled-field';
import DropdownBox from '../../dropdown-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './guest-details.module.scss';
import formStyles from '../../../styles/forms.module.scss';

const GuestDetails = (props) => {

    const guestText = props.guestLimit > 20 ?
        <p>Guests are welcome free of charge. Please confirm details of any guests attending with you on the day</p> :
        <p>You're welcome to bring up to {props.guestLimit} extra guests to this event. Please enter their details below:</p>


    return (
        <DropdownBox title="Guests">
            {guestText}

            <FieldArray name="guests">
                {({ insert, remove, push }) => (<>
                    <div>
                        {props.guests.length > 0 && props.guests.map((guest, index) => (
                            <div key={index} className={styles.guestRow}>
                                <div class={styles.guestInput}>
                                    <LabelledField name={`guests.${index}.name`} label="Guests Name" type="text" />
                                </div>
                                <div class={styles.guestInput}>
                                    <LabelledField name={`guests.${index}.email`} label="Guests Email Address (optional)" type="text" />
                                </div>
                                <div class={styles.guestInput}>
                                    <LabelledField name={`guests.${index}.phone`} label="Guests Phone Number (optional)" type="text" />
                                </div>
                                
                                <div class={styles.removeGuest}>
                                    <button onClick={() => remove(index)} className={formStyles.redButton} type="button">
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                    <div className={styles.addGuest}>
                        <button onClick={() => push({ name: '', email: '', phone: ''})} className={formStyles.button}
                        disabled={props.guests.length >= props.guestLimit}
                        type="button">
                            Add Guest
                        </button>
                    </div>
                </>)}
            </FieldArray>
        </DropdownBox>
    );
}

export default GuestDetails;