import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import styles from './app.module.scss'
import RegistrationEntry from '../registration-entry/registration-entry';
import BookingRegistration from '../booking-registration';
import NotFound from '../not-found/not-found';
import classNames from "classnames";
import {getIsCroft} from "../../utils/croft";

export default function App() {
    
    const cn = classNames(styles.main, {
        [`${styles.croft}`]: getIsCroft(),
    });
    
    return (
        <div className={cn}>
            <Header />
            
            <Router>
                <Switch>
                    <Route path="/track/:eventDate" children={<RegistrationEntry />} />
                    <Route path="/experience" children={<BookingRegistration />} />
                    <Route exact path="/" children={<BookingRegistration />} />
                    <Route children={<NotFound />} />
                </Switch>
            </Router>
            
            <Footer />
        </div>
    );
}