import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RegistrationService from '../../utils/registration-service';
import TrackdayRegistration from '../trackday-registration';
import EventNotFound from '../event-not-found/event-not-found';

function RegistrationEntry() {

    const { eventDate } = useParams();
    const [ eventDetails, setEventDetails ] = useState(null);
    const [ error, setError ] = useState(null);

    useEffect(() => {
        if (eventDetails != null || error != null) return;

        var regService = new RegistrationService();
        regService.getEvent(eventDate)
            .then((e) => {
                if (e == null) {
                    setError(new Error("Event not found"))
                } else {
                    setEventDetails(e);
                }
            })
            .catch((err) => {
                setError(err);
            });
    });

    let pageData;
    
    if (error != null) {
        // Error
        pageData = <EventNotFound />
    }
    else if (eventDetails != null) {
        // Loaded
        pageData = <TrackdayRegistration eventDetails={eventDetails}></TrackdayRegistration>
    }
    else {
        // Loading
        pageData = <p>Loading...</p>
    }

    return (
        <>
            {pageData}
        </>
    );
}

export default RegistrationEntry;