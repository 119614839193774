import React, { Component } from 'react';
import styles from './booking-details.module.scss';
import ChevronBox from '../../chevron-box';
import moment from 'moment';

class BookingDetails extends Component {
    render() {

        const bookingRef = this.props.details.bookingRef;
        const experience = this.props.details.experience;
        const eventDate = moment(this.props.details.eventDate).format('MMMM Do YYYY');
        const session = this.props.details.session;

        return <div>
            <ChevronBox title="Booking Details">
                <dl className={styles.details}>
                    <div>
                        <dt>Booking Reference</dt>
                        <dd>{bookingRef}</dd>
                    </div>
                    <div>
                        <dt>Driving Experience</dt>
                        <dd>{experience}</dd>
                    </div>

                    <div>
                        <dt>Event Booked</dt>
                        <dd>{eventDate}</dd>
                    </div>

                    <div>
                        <dt>Session Booked</dt>
                        <dd>{session}</dd>
                    </div>
                </dl>
            </ChevronBox>
        </div>
    }
}

export default BookingDetails;