import React from 'react';
import DropdownBox from '../../dropdown-box';
import LabelledField from '../../labelled-field';
import styles from './purchaser-details.module.scss';

const PurchaserDetails = () => {

    return (
        <DropdownBox title="Customer Details" contentClass={styles.detailsForm}>
            <div className={styles.col}>
                <LabelledField id="title" name="title" label="Title" autoComplete="honorific-prefix" type="text" />
                <LabelledField id="forename" name="forename" label="First Name" placeholder="First Name" autoComplete="given-name" type="text" />
                <LabelledField id="surname" name="surname" label="Last Name" placeholder="Last Name" autoComplete="family-name" type="text" />
                <LabelledField id="email" name="email" label="Email Address" placeholder="Email Address" autoComplete="email" type="text" />
                <LabelledField id="phone" name="phone" label="Phone Number" autoComplete="tel" type="text" />
            </div>

            <div className={styles.col}>
                <LabelledField id="address1" name="address1" label="Address 1" autoComplete="address-line1" type="text" />
                <LabelledField id="address2" name="address2" label="Address 2" autoComplete="address-line2" type="text" />
                <LabelledField id="town" name="town" label="Town" autoComplete="address-locality1" type="text" />
                <LabelledField id="postcode" name="postcode" label="Postcode" autoComplete="postal-code" type="text" />
            </div>

            <div className={styles.col}>

            </div>
        </DropdownBox>
    )
}

export default PurchaserDetails;