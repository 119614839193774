import React from 'react';
import styles from './dropdown-box.module.scss';

const DropdownBox = (props) => {

    var contentClasses = styles.content;
    if (props.contentClass) contentClasses += ' ' + props.contentClass;

    return (
        <section className={styles.box}>
            <div className={styles.header}>
                <h2>{props.title}</h2>
            </div>
            <div className={contentClasses}>
                {props.children}
            </div>
        </section>
    )
}

export default DropdownBox;