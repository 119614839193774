import React, { Component } from 'react';
import styles from './chevron-box.module.scss';

class ChevronBox extends Component {
    
    render() {
        return <div className={styles.box}>
            <h2 className={styles.title}>{this.props.title ?? ''}</h2>
            <div className={styles.content}>
                {this.props.children}
            </div>
        </div>
    }
}

export default ChevronBox;