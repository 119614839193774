import React, { Component } from 'react';
import NavElement from './navelement';
import styles from './navbar.module.scss';

class Navbar extends Component {
    render() {
        var states = [];

        switch (this.props.step) {
            case 0:
                states = ["active", "upcoming", "upcoming"];
                break;
            case 1:
                states = ["completed", "active", "upcoming"];
                break;
            case 2:
                states = ["completed", "completed", "active"];
                break;
            default:
                states = ["upcoming", "upcoming", "upcoming"];
                break;
        }
        
        return <nav className={styles.container}>
            <ol className={styles.list}>
                <NavElement navState={states[0]} text="Booking Details"/>
                <NavElement navState={states[1]} text="Online Registration"/>
                <NavElement navState={states[2]} text="Confirmation" />
            </ol>
        </nav>
    }
}

export default Navbar;