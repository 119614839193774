import config from './config';
import moment from 'moment';

class RegistrationService {

    constructor() {
        this.validateDetails = this.validateDetails.bind(this);
        this.register = this.register.bind(this);
    }

    async validateDetails(date, part1, part2) {

        // Build query
        const params = new URLSearchParams({
            part1: part1,
            part2: part2
        });
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const url = `${config.apiBase}api/trackdays/event/${formattedDate}/find?${params.toString()}`

        var fetchResult = await fetch(url);
        if (!fetchResult.ok) {
            throw 'Unable to fetch from API';
        }

        var response = await fetchResult.json();
        if (response.length === 0) return false;

        var customer = response[0].customer;
        var event = response[0].event;
        var address = response[0].address;

        var sessions = response.filter(x => x.event.id === event.id);

        return {
            orderRef: response[0].orderReference,
            emailAddress: customer.emailAddress,

            auth: {
                part1: part1,
                part2: part2
            },

            eventDate: event.eventDate,
            eventName: event.name,
            additionalGuests: [],
            maxAdditionalGuests: event.maxAdditionalGuests,
            
            sessions: sessions.map(x => x.sessionTitle),

            terms: event.terms,

            purchaser: {
                title: customer.title ?? '',
                forename: customer.forename ?? '',
                surname: customer.surname ?? '',
                email: customer.emailAddress ?? '',
                phone: customer.telephoneNumber ?? '',

                address: {
                    line1: address.line1 ?? '',
                    line2: address.line2 ?? '',
                    town: address.town ?? '',
                    county: null,
                    postcode: address.postCode ?? '',
                    country: null
                }
            }
        };
    }

    async register(details) {
        // Format the request
        var data = {
            orderReference: details.orderRef,
            orderEmail: details.emailAddress,

            title: details.purchaser.title,
            forename: details.purchaser.forename,
            surname: details.purchaser.surname,
            email: details.purchaser.email,
            phone: details.purchaser.phone,

            address: details.purchaser.address,

            guests: details.additionalGuests,

            termsAccepted: true,

            authenticationPart1: details.auth.part1,
            authenticationPart2: details.auth.part2
        };

        const eventDate = moment(details.eventDate).format("YYYY-MM-DD");
        const url = `${config.apiBase}api/trackdays/event/${eventDate}/register`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: 'no-cache',
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Error while registering');
        }

        return details;
    }

    async getEvent(eventDate) {
        var date = moment(eventDate);
        var url = config.apiBase + "api/trackdays/event/" + date.format('YYYY-MM-DD');

        var fetchResult = await fetch(url);
        if (!fetchResult.ok) {
            throw new Error('Unable to fetch from API, status code: ' + fetchResult.status);
        }

        var response = await fetchResult.json();
        if (response.length === 0) return false;

        return {
            id: response.id,
            name: response.name,
            eventDate: response.eventDate,
            maxAdditionalGuests: response.maxAdditionalGuests,
            terms: response.terms,
            registerFrom: response.registerFrom,
            registrationEnabled: response.registrationEnabled,
            registrationDisabledMessage: response.registrationDisabledMessage,
            authenticationScheme: response.authenticationScheme
        }
    }

}

export default RegistrationService;