import React from 'react';
import styles from './experience-login.module.scss';
import ValidationSummary from '../validation-summary';
import BookingRegistrationService from '../../utils/booking-registration-service';
import {Form, Formik} from 'formik';
import LabelledField from '../labelled-field';
import * as Yup from 'yup';
import Circuit from "../circuit";

export default (props) => {
    

    const DetailsSchema = Yup.object().shape({
        orderRef: Yup.string()
            .trim()
            .required('Please enter your booking reference'),

        surname: Yup.string()
            .trim()
            .required('Please enter your surname')
    });

    return (<>
        <h1>Online Registration</h1>
        <h3>Driving Experiences</h3>
        <p>We're looking forward to welcoming you to <Circuit.Name/>. All visitors must complete the online registration process before attending.
            Please enter your details below to start the registration process.
        </p>
        <p>Please note that this form is only for guests on driving experience days; if you're a competitor or participating in a test day and looking to
            check-in, please use the link provided to you in your email.
        </p>
        <Formik
            initialValues={{
                orderRef: '',
                surname: ''
            }}
            validationSchema={DetailsSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {

                const service = new BookingRegistrationService();
                try {
                    const result = await service.validateDetails(values.orderRef.trim(), values.surname.trim());
                    if (!result) {
                        actions.setFieldError('form', 'We were unable to find your booking details. Please make sure that your reference number and surname are correct');
                        actions.setSubmitting(false);
                        return;
                    }

                    console.log('Details validated');
                    if (props.onDetailsValidated) props.onDetailsValidated(result);

                } catch (error) {
                    actions.setFieldError('form', 'An unknown error occurred while checking your details. Please try again later');
                    actions.setSubmitting(false);
                    return;
                }


                
            }}
        
        >
            {({values, errors, isSubmitting}) => (<>
                <ValidationSummary errors={errors} />
                <Form className={styles.form}>
                    <LabelledField type="text" name="orderRef" label="Booking Reference" placeholder="B1234567" />
                    <LabelledField type="text" name="surname" label="Surname" placeholder="Smith" />

                    <div className={styles.buttonContainer}>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </div>
                </Form>
            </>)}
        </Formik>

    </>);
}