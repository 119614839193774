import React from 'react';
import PurchaserDetails from './purchaser-details/purchaser-details';
import { Formik, Form } from 'formik';
import GuestDetails from './guest-details/guest-details';
import Terms from './terms/terms';
import styles from './registration-details.module.scss';
import * as Yup from 'yup';
import RegistrationService from '../../utils/registration-service';
import BookingRegistrationService from '../../utils/booking-registration-service';
import ValidationSummary from '../validation-summary/validation-summary';
import BookingDetails from './booking-details/booking-details';
import EventDetails from './event-details/event-details';

const RegistrationDetails = (props) => {

    const details = props.details;

    const detailsElement = props.type === "booking" ?
        <BookingDetails details={props.details} /> :
        <EventDetails details={props.details} />;

    const RegistrationSchema = Yup.object().shape({
        title: Yup.string()
            // eslint-disable-next-line
            .max(5, 'Title can be a maximum of ${max} characters'),

        forename: Yup.string()
            .required('Please enter your first name')
            // eslint-disable-next-line
            .max(30, 'Forename can be a maximum of ${max} characters'),
    
        surname: Yup.string()
            .required('Please enter your last name')
            // eslint-disable-next-line
            .max(30, 'Surname can be a maximum of ${max} characters'),
    
        email: Yup.string()
            .required('Please enter your email address')
            .email('Please enter a valid email address')
            // eslint-disable-next-line
            .max(50, 'Email address can be a maximum of ${max} characters'),
    
        phone: Yup.string()
            .required('Please enter your phone number')
            // eslint-disable-next-line
            .max(20, 'Phone number can be a maximum of ${max} characters'),
    
        address1: Yup.string()
            .required('Please enter your address'),
    
        town: Yup.string()
            .required('Please enter your post town'),
    
        postcode: Yup.string()
            .required('Please enter your post code')
            // eslint-disable-next-line
            .max(10, 'Postcode can be a maximum of ${max} characters'),

        guests: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                        .required('Please enter your guest\'s name')
                        // eslint-disable-next-line
                        .max(200, 'Your guest\'s name can be a maximum of ${max} characters'),

                    email: Yup.string()
                        // eslint-disable-next-line
                        .max(100, 'Your guest\'s email address can be a maximum of ${max} characters')
                        .email('Please enter a valid email address for your guest'),

                    phone: Yup.string()
                        // eslint-disable-next-line
                        .max(100, 'Your guest\'s phone number can be a maximum of ${max} characters')
                })
            )
            .compact(v => !v.name && !v.email && !v.phone),
    
        terms: Yup.array()
            .min(details.terms.length, 'Please accept all terms and conditions')
            .of(Yup.boolean().oneOf([true], 'Please accept all terms and conditions'))
    });

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                title: details.purchaser.title,
                forename: details.purchaser.forename,
                surname: details.purchaser.surname,
                email: details.purchaser.email,
                phone: details.purchaser.phone,

                address1: details.purchaser.address.line1,
                address2: details.purchaser.address.line2,
                town: details.purchaser.address.town,
                postcode: details.purchaser.address.postcode,

                guests: [],
                terms: []
            }}
            validationSchema={RegistrationSchema}
            onSubmit={async (values, actions) => {
                // Format the form
                details.purchaser.title = values.title;
                details.purchaser.forename = values.forename;
                details.purchaser.surname = values.surname;
                details.purchaser.email = values.email;
                details.purchaser.phone = values.phone;

                details.purchaser.address.line1 = values.address1;
                details.purchaser.address.line2 = values.address2;
                details.purchaser.address.town = values.town;
                details.purchaser.address.postcode = values.postcode;

                details.additionalGuests = values.guests.map(x => ({
                    name: x.name,
                    email: x.email,
                    phone: x.phone
                }));

                // Send it off
                try {
                    if (props.type === 'booking') {
                        var service = new BookingRegistrationService();
                        await service.register(details);
                    }
                    else {
                        var service = new RegistrationService();
                        await service.register(details);
                    }
                } catch (err) {
                    alert("Error: " + err.toString()); //TODO
                    actions.setSubmitting(false);
                    return;
                }

                // Raise event
                actions.setSubmitting(false);
                if (props.onRegister) props.onRegister(details);
            }}
        >
            {({values, errors, isSubmitting}) => (
                <Form>
                    <ValidationSummary errors={errors} />
                    {detailsElement}
                    <PurchaserDetails details={props.details} />
                    <GuestDetails guests={values.guests} guestLimit={details.maxAdditionalGuests} />
                    <Terms terms={details.terms} />
                    <ValidationSummary errors={errors} />
                    <div className={styles.submit}>
                        <button type="submit" disabled={isSubmitting}>
                            Complete Registration
                        </button>
                    </div>
                    
                </Form>
            )}
        </Formik>
    );
}

export default RegistrationDetails;