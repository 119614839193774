import React from 'react';
import styles from './booking-details.module.scss';
import ValidationSummary from '../validation-summary';
import RegistrationService from '../../utils/registration-service';
import { Formik, Form } from 'formik';
import LabelledField from '../labelled-field';
import * as Yup from 'yup';

export default (props) => {

    const eventDetails = props.eventDetails;

    let fieldDetails;

    switch (eventDetails.authenticationScheme) {
        case 0: // ReferenceAndEmail
            fieldDetails = [
                {
                    title: 'Order Reference',
                    placeholder: '1124-1230',
                    validation: Yup.string()
                        .trim()
                        .required('Please enter your order reference')
                },
                {
                    title: 'Email Address',
                    placeholder: 'jim@example.com',
                    validation: Yup.string()
                        .trim()
                        .required('Please enter your email address')
                        .email('Please enter a valid email address')
                }
            ];
            break;
        case 1: // EmailAndSurname
            fieldDetails = [
                {
                    title: 'Email Address',
                    placeholder: 'you@example.com',
                    validation: Yup.string()
                        .trim()
                        .required('Please enter your email address')
                        .email('Please enter a valid email address')
                },
                {
                    title: 'Surname',
                    placeholder: 'Smith',
                    validation: Yup.string()
                        .trim()
                        .required('Please enter your surname')
                }
            ];
            break;
        default:
            alert('Unknown authentication scheme')
            break;
    }

    const DetailsSchema = Yup.object().shape({
        part1: fieldDetails[0].validation,
        part2: fieldDetails[1].validation
    });

    return (<>
        <h1>Online Registration</h1>
        <h3>{eventDetails.name}</h3>
        <p>We're looking forward to welcoming you to Thruxton. All visitors must complete the online registration process before attending.
            Please enter your details below to start the registration process.
        </p>
        <Formik
            initialValues={{
                part1: '',
                part2: ''
            }}
            validationSchema={DetailsSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
                const service = new RegistrationService();
                try {
                    const result = await service.validateDetails(eventDetails.eventDate, values.part1.trim(), values.part2.trim());
                    if (!result) {
                        actions.setFieldError('form', 'We were unable to find your booking details. Please make sure that the details you have provided are correct');
                        actions.setSubmitting(false);
                        return;
                    }

                    if (props.onDetailsValidated) props.onDetailsValidated(result);

                } catch (error) {
                    alert(error);
                    actions.setFieldError('form', 'An unknown error occurred while checking your details. Please try again later');
                    actions.setSubmitting(false);
                    return;
                }


                
            }}
        
        >
            {({values, errors, isSubmitting}) => (<>
                <ValidationSummary errors={errors} />
                <Form className={styles.form}>
                    <LabelledField type="text" name="part1" label={fieldDetails[0].title} placeholder={fieldDetails[0].placeholder} />
                    <LabelledField type="text" name="part2" label={fieldDetails[1].title} placeholder={fieldDetails[1].placeholder} />

                    <div className={styles.buttonContainer}>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </div>
                </Form>
            </>)}
        </Formik>

    </>);
}