import React from 'react';
import Logo from '../logo';
import styles from './footer.module.scss';

export default () => {
    return (
        <footer className={styles.footer}>
            <div><Logo/></div>
        </footer>
    );
}