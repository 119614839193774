import React from 'react';
import Navbar from '../navbar';

export default function EventNotFound() {
    return (
        <>
        <Navbar step={0}></Navbar>
        <h1>Online Registration</h1>
        <p>
            Sorry, we couldn't find the event you were looking for. You may have mis-typed the URL, the event may have passed, or the event may now be closed to registrations.
            Please check the URL and try again. If you're still having problems, please call us on <a href="tel:+441264882222">01264 882222</a> or email us
            at <a href="mailto:info@thruxtonracing.co.uk">info@thruxtonracing.co.uk</a>.

        </p>
        </>
    );
}