import React from 'react';
import formStyles from '../../styles/forms.module.scss';
import { Field } from 'formik';

const LabelledField = (props) => {

    const fieldId = props.id ?? props.name;

    return (
        <div className={formStyles.formField}>
            <label htmlFor={fieldId}>{props.label}</label>
            <Field {...props} id={fieldId} />
        </div>
    )

}

export default LabelledField;