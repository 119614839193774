import React from 'react';
import styles from './checkbox.module.scss';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Checkbox = (props) => {

    const fieldId = props.id ?? props.name;
    const {
        children,
        label,
        ...cleanProps
    } = props;

    return (
        <label className={styles.checkbox}>
            <Field type="checkbox" {...cleanProps} id={fieldId} />
            <span className={styles.checkmarkContainer}>
                <FontAwesomeIcon icon={faCheck} className={styles.checkmark} />
            </span>
            <span class={styles.text}>
                {props.children}
            </span>
        </label>
    )

}

export default Checkbox;