import React, { Component } from 'react';
import styles from './event-details.module.scss';
import ChevronBox from '../../chevron-box';
import moment from 'moment';

class EventDetails extends Component {
    render() {

        const orderRef = this.props.details.orderRef;
        const eventName = this.props.details.eventName;
        const eventDate = moment(this.props.details.eventDate).format('MMMM Do YYYY');
        const sessions = this.props.details.sessions.map(x => <span>{x}</span>);

        return <div>
            <ChevronBox title="Event Details">
                <dl className={styles.details}>
                    <div>
                        <dt>Order Reference</dt>
                        <dd>{orderRef ?? 'N/A'}</dd>
                    </div>
                    <div>
                        <dt>Event</dt>
                        <dd>{eventName}</dd>
                    </div>

                    <div>
                        <dt>Date</dt>
                        <dd>{eventDate}</dd>
                    </div>

                    <div>
                        <dt>Booked Sessions</dt>
                        <dd>{sessions}</dd>
                    </div>
                </dl>
            </ChevronBox>
        </div>
    }
}

export default EventDetails;