import React from 'react';
import styles from './registration-confirmation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import Circuit from "../circuit";

export default (props) => {

    const details = props.details;
    

    const formattedName = [details.purchaser.title, details.purchaser.forename, details.purchaser.surname]
        .filter(Boolean).join(' ');

    const guests = details.additionalGuests.length > 0 ?
        details.additionalGuests.map(x => <span>{x.name}</span>)
        : <em>None</em>;

    const address = props.details.purchaser.address;
    const formattedAddress = [address.line1, address.line2, address.town, address.postcode]
        .filter(Boolean)
        .map(x => <span>{x}</span>);

    return (
        <>
            <h1>Registration Confirmation</h1>
            <div className={styles.successBox}>
                <strong>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    You've successfuly registered
                </strong>
            </div>

            <p>Thank you for registering online. We look forward to welcoming you to <Circuit.Name/>! You should receive an email confirmation within
                the next 10 minutes - if you have any questions please get in touch on <Circuit.Phone/> or email&nbsp;
                <Circuit.Email/>
            </p>

            <div className={styles.detailsBox}>
                <dl>
                    <div>
                        <dt>Your Name</dt>
                        <dd>{formattedName}</dd>
                    </div>

                    <div>
                        <dt>Email Address</dt>
                        <dd>{details.purchaser.email}</dd>
                    </div>

                    <div>
                        <dt>Phone Number</dt>
                        <dd>{details.purchaser.phone}</dd>
                    </div>

                    <div>
                        <dt>Postal Address</dt>
                        <dd className={styles.postalAddress}>{formattedAddress}</dd>
                    </div>

                    <div>
                        <dt>Order Reference</dt>
                        <dd>{details.orderRef ?? 'N/A'}</dd>
                    </div>

                    <div>
                        <dt>Your Guests</dt>
                        <dd className={styles.guests}>{guests}</dd>
                    </div>
                </dl>
            </div>
        </>
    )
}