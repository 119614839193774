import React, { Component } from 'react';
import styles from './header.module.scss';
import Logo from '../logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons'
import Circuit from "../circuit";

class Header extends Component {
    render() {
        return <header className={styles.header}>
            <div className={styles.logo}>
                <Logo />
            </div>

            <div className={styles.details}>
                <ul>
                    <li>
                        <span className={styles.icon}><FontAwesomeIcon icon={faAt} /></span>
                        <Circuit.Email/>
                    </li>
                    <li>
                        <span className={styles.icon}><FontAwesomeIcon icon={faPhone} /></span>
                        <Circuit.Phone/>
                    </li>
                </ul>
            </div>
        </header>
    }
}

export default Header;