import React from 'react';
import styles from './validation-summary.module.scss';

const extractErrors = (errors) => {

    const messageList = [];

    for (const error in errors) {
        if (errors.hasOwnProperty(error)) {
            const element = errors[error];
            
            if (typeof element === 'object') {
                Array.prototype.push.apply(messageList, extractErrors(element));
            } else {
                messageList.push(element);
            }
        }
    }

    return messageList;
}

export default (props) => {
    const errors = props.errors ?? {};
    const messages = extractErrors(errors);

    if (messages.length === 0) {
        return null;
    }

    const listItems = [];

    for (const message of messages) {
        listItems.push(<li>{message}</li>);    
    }

    return (<div className={styles.errors}>
        <ul className={styles.list}>
            {listItems}
        </ul>
    </div>)
}